<template>
	<div class="total drag" v-draw id="CfbAirSoft">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_风量软测量</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				风量软测量
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context flex">
			<div class="flex">
				<div class="one">
					<div class="word">一次风量</div>
					<div class="table1">
						<div class="bottom flex">
							<div class="column1 left">测量方式</div>
							<div class="column1">实际测量</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量K值1</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLXS,'软测量K值1','FLXS','MCSYCL')">
								{{infoList.MCSYCL.FLXS}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量C值1</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLCS,'软测量C值1','FLCS','MCSYCL')">
								{{infoList.MCSYCL.FLCS}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量结果1</div>
							<div class="column4" @click="toCompon(2,'JSFL11','MCSYCL','JSFL11VV1','1#一次风计算风量')"
								@dblclick="Cclick(infoList.MCSYCL.JSFL11,'JSFL11','MCSYCL','JSFL11VV1')">
								{{infoList.MCSYCL.JSFL11}}
							</div>
						</div>
						<div class="flex">
							<div class="column2 left">实际测量1</div>
							<div class="column4" @click="toCompon(2,'FT104D_B','MCSSOURCE','FT104D_BVV1','1#一次风量')"
								@dblclick="Cclick(infoList.MCSSOURCE.FT104D_B,'FT104D_B','MCSSOURCE','FT104D_BVV1')">
								{{infoList.MCSSOURCE.FT104D_B}}
							</div>
						</div>
					</div>
					<div class="table2">
						<div class="bottom flex">
							<div class="column2 left">预测风量</div>
							<div class="column4" @click="toCompon(2,'JSFL','MCSYCL','JSFLVV1','一次风量')"
								@dblclick="Cclick(infoList.MCSYCL.JSFL,'JSFL','MCSYCL','JSFLVV1')">
								{{infoList.MCSYCL.JSFL}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量K值2</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLXS2,'软测量K值2','FLXS2','MCSYCL')">
								{{infoList.MCSYCL.FLXS2}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量C值2</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLCS2,'软测量C值2','FLCS2','MCSYCL')">
								{{infoList.MCSYCL.FLCS2}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量结果2</div>
							<div class="column4" @click="toCompon(2,'JSFL12','MCSYCL','JSFL12VV1','2#一次风计算风量')"
								@dblclick="Cclick(infoList.MCSYCL.JSFL12,'JSFL12','MCSYCL','JSFL12VV1')">
								{{infoList.MCSYCL.JSFL12}}
							</div>
						</div>
						<div class="flex">
							<div class="column2 left">实际测量2</div>
							<div class="column4" @click="toCompon(2,'FT107D_B','MCSSOURCE','FT107D_BVV1','2#一次风量')"
								@dblclick="Cclick(infoList.MCSSOURCE.FT107D_B,'FT107D_B','MCSSOURCE','FT107D_BVV1')">
								{{infoList.MCSSOURCE.FT107D_B}}
							</div>
						</div>
					</div>
				</div>
				<div class="two">
					<div class="word">二次风量</div>
					<div class="table1">
						<div class="bottom flex">
							<div class="column1 left">测量方式</div>
							<div class="column1">实际测量</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量K值1</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLXS3,'软测量K值1','FLXS3','MCSYCL')">
								{{infoList.MCSYCL.FLXS3}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量C值1</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLCS3,'软测量C值1','FLCS3','MCSYCL')">
								{{infoList.MCSYCL.FLCS3}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量结果1</div>
							<div class="column4" @click="toCompon(2,'JSFL21','MCSYCL','JSFL21VV1','1#二次风计算风量')"
								@dblclick="Cclick(infoList.MCSYCL.JSFL21,'JSFL21','MCSYCL','JSFL21VV1')">
								{{infoList.MCSYCL.JSFL21}}
							</div>
						</div>
						<div class="flex">
							<div class="column2 left">实际测量1</div>
							<div class="column4" @click="toCompon(2,'FT105D_B','MCSSOURCE','FT105D_BVV1','1#二次风量')"
								@dblclick="Cclick(infoList.MCSSOURCE.FT105D_B,'FT105D_B','MCSSOURCE','FT105D_BVV1')">
								{{infoList.MCSSOURCE.FT105D_B}}
							</div>
						</div>
					</div>
					<div class="table2">
						<div class="bottom flex">
							<div class="column2 left">预测风量</div>
							<div class="column4" @click="toCompon(2,'ECFL','MCSYCL','ECFLVV1','二次风量')"
								@dblclick="Cclick(infoList.MCSYCL.ECFL,'ECFL','MCSYCL','ECFLVV1')">
								{{infoList.MCSYCL.ECFL}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量K值2</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLXS4,'软测量K值2','FLXS4','MCSYCL')">
								{{infoList.MCSYCL.FLXS4}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量C值2</div>
							<div class="column3" @click="toIpt(infoList.MCSYCL.FLCS4,'软测量C值2','FLCS4','MCSYCL')">
								{{infoList.MCSYCL.FLCS4}}
							</div>
						</div>
						<div class="bottom flex">
							<div class="column2 left">软测量结果2</div>
							<div class="column4" @click="toCompon(2,'JSFL22','MCSYCL','JSFL22VV1','2#二次风计算风量')"
								@dblclick="Cclick(infoList.MCSYCL.JSFL22,'JSFL22','MCSYCL','JSFL22VV1')">
								{{infoList.MCSYCL.JSFL22}}
							</div>
						</div>
						<div class="flex">
							<div class="column2 left">实际测量2</div>
							<div class="column4" @click="toCompon(2,'FT106D_B','MCSSOURCE','FT106D_BVV1','2#二次风量')"
								@dblclick="Cclick(infoList.MCSSOURCE.FT106D_B,'FT106D_B','MCSSOURCE','FT106D_BVV1')">
								{{infoList.MCSSOURCE.FT106D_B}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList">
		</Manual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'AirSoft', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbAirSoft {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			line-height: 2vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.4vw;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 1.5vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 2vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 50vw;
			height: 46vh;
			margin-top: 3vh;
			margin-left: 1.5vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.word {
				width: 24.3vw;
				font-size: 1.6vw;
				line-height: 5vh;
				color: #8aeaff;
				text-align: center;
			}

			.table1 {
				width: 24.3vw;
				height: 20vh;
				font-size: 1.3vw;
				line-height: 3.9vh;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;

				.column1 {
					width: 12.15vw;
					color: #2fc3e3;
					text-align: center;
				}

				.column2 {
					width: 12.15vw;
					color: #8aeaff;
					text-align: center;
				}

				.column3 {
					width: 12.15vw;
					color: #00ffb4;
					text-align: center;
				}

				.column4 {
					width: 12.15vw;
					color: #8aeaff;
					text-align: center;
				}

				.left {
					border-right: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
				}

				.bottom {
					border-bottom: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
				}
			}

			.table2 {
				width: 24.3vw;
				height: 20vh;
				font-size: 1.3vw;
				line-height: 3.9vh;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
				margin-top: 2.9vh;

				.column2 {
					width: 12.15vw;
					color: #8aeaff;
					text-align: center;
				}

				.column3 {
					width: 12.15vw;
					color: #00ffb4;
					text-align: center;
				}

				.column4 {
					width: 12.15vw;
					color: #8aeaff;
					text-align: center;
				}

				.left {
					border-right: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
				}

				.bottom {
					border-bottom: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
				}
			}

			.two {
				margin-left: 1.4vw;
			}
		}
	}
</style>
